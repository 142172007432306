@import '../../../../shared/styles/colors.scss';

.tabs-heading-wrapper {
    margin-bottom: 10px;

    &__heading {
        span {
            text-transform: uppercase;
            color: $orange-500;
            background: $white-000;
        }
    }
}

.add-new-member-faq-container {
    padding: 30px;

    &__body {
        background: $white-000;
        border-radius: 16px;
        padding: 15px;

        .d-grid {
            display: grid;
            grid-template-columns: 10fr 0.5fr;
            align-items: center;
            margin-bottom: 15px;
        }

        .term-d-grid {
            display: grid;
            grid-template-columns: 10fr 0.8fr;
            align-items: center;
            margin-bottom: 15px;
        }

        .t-right {
            display: flex;
            justify-content: center;
            align-items: center;

        }

        .version-wrapper {
            margin-left: 15px;

            h5 {
                margin-bottom: 0px;
                font-size: 16px;
                font-weight: 500;
                color: $grey-1000;
            }

            div {
                font-size: 17px;
                font-weight: 500;
                color: $grey-1000;
                text-align: center;
            }
        }

        .dd-grid {
            display: grid;
            grid-template-columns: 5fr 5fr 0.5fr;
            // align-items: center;
            margin-bottom: 15px;
            gap: 10px;

            .length {
                text-align: end;
            }
        }
        .faq-date-wrapper{
            display: grid;
            grid-template-columns: 5fr 5fr 0.5fr;
            gap: 10px;
            .ant-picker {
                width: 100%;
                height: 44px;
                background-color: $grey-100;
                border: none;
                border-radius: 16px;
            }
            .add-cat-label{
                display: block;
            }
        }

        .grey-text {
            font-size: 17px;
            font-weight: 600;
            color: $grey-1000;
        }

        .orng-text {
            font-size: 17px;
            font-weight: 600;
            color: $orange-700;
            margin-left: 5px;
        }

        .mr-10 {
            button {
                margin-right: 10px;
            }
        }

        .add-new-question-answer-btn {
            cursor: pointer;
            width: fit-content;
        }

        .add-faq-action-btn-wrapper {
            margin-top: 15px;
            display: flex;
            justify-content: space-between;
        }

        .video-input-wrapper {
            margin-bottom: 10px;

            .ant-upload-select {
                label {
                    display: block;
                }
            }

            .ant-picker {
                width: 160px;
                height: 44px;
                background-color: $grey-100;
                border: none;
                border-radius: 16px;
            }

            .add-cat-label,
            .video-label {
                display: block;
            }

            .d-flex {
                display: flex;
                align-items: center;

                span {
                    margin-left: 5px;
                }
                input{
                    width: 160px;
                    height: 44px;
                }
            }
            .lenght{
                color: $grey-1000;
                font-size: 15px;
                font-weight: 300;
                text-align: end;
            }
        }
    }

    &__heading {
        font-size: 16px;
        font-weight: 500;
        color: $orange-700;
        margin-bottom: 10px;
    }
}