@import '../../shared/styles/colors.scss';

.container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    height: 100vh;
    padding-top: 40px;

    .formBox {
        border-radius: 16px;
        padding: 32px;
        background-color: $white-000;
        box-shadow: 0 2px 4px #393B3D03;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 360px;
        justify-content: flex-start;

        .welcomeText {
            margin-bottom: 24px;

            p {
                text-align: center;
                margin: 0;
                font-weight: 300;
                font-size: 15px;
                color: $grey-1000;
            }
        }

        img {
            margin-bottom: 24px;
        }

        .formfield {
            margin-bottom: 16px;
        }

        .actionContainer {
            width: 100%;
            display: flex;
            align-items: stretch;
            flex-direction: column;
        }

        .signUpPrompt {
            font-size: 13px;
            text-align: center;
            color: $grey-1000;

            a {
                color: $orange-700;
                margin-left: 4px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}