@import '../../../../../../shared/styles/colors.scss';

.add-procedure-container {
    background: $white-000;

    &__body {
        padding: 30px;

        .procedure-id-spn {
            color: $grey-1000;
            font-size: 17px;
            font-weight: 500;
        }

        .rv-procedure-dropdown {
            width: 285px;

            .ant-select-selector {
                height: 44px;
                padding: 5px 11px;
                background: $grey-100;
            }
        }



        .ant-picker {
            width: 286px;
            height: 44px;
            background-color: $grey-100;
            border: none;
            border-radius: 16px;
        }

        h4 {
            color: $orange-700;
            font-weight: 500;
            font-size: 16px;
        }

        label {
            color: $grey-1000;
            font-size: 16px;
            font-weight: 500;
            display: block;
        }

        .text-length {
            text-align: end;
            color: $grey-1000;
            font-size: 15px;
            font-weight: 300;
            margin-top: 2px;
        }

        .procedure-name-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 0px 40px;

            .procedure-id {
                border: none !important;
                outline: none;
            }
        }

        .code-upload-wrapper {
            display: grid;
            grid-template-columns: 1fr 2fr;
            gap: 0px 40px;

            .d-flex {
                display: flex;
                justify-content: space-between;
            }
        }

        .description-wrapper {
            .ant-form-item {
                margin-bottom: 0px;
            }
        }

        .order-deliver-wrapper {
            display: grid;
            grid-template-columns: 1fr 2fr;
            gap: 0px 40px;
        }


        .m-25 {
            margin-bottom: 25px;

            .orange-fin {
                color: $orange-700;
            }
        }

        .provider-delivery-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;

            .provider-wrapper {
                margin-right: 50px;
                border-right: 1px solid $grey-200;
            }

            h3 {
                color: $orange-700;
                font-weight: 500;
                font-size: 16px;
            }

            label {
                display: flex;
            }

            .d-flex {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }

            .ml-30 {
                margin-left: 30px;
            }
        }

        .procedure-short-input {
            width: 286px;

            .input-span-wrapper {
                display: flex;
                align-items: center;
                gap: 5px;

                .rv-checkbox {
                    display: flex;
                }

                span {
                    // margin-left: 8px;
                    // margin-right: 8px;
                    // margin-top: 20px;
                    color: $grey-800;
                    font-size: 17px;
                    font-weight: 500;
                }

                .percent {
                    // margin-left: 8px;
                }
            }

            .v-hidden {
                visibility: hidden;
            }
        }

        .procedure-medium-input {
            width: 380px !important;
        }

        .border-line {
            width: 96%;
            background: $grey-200;
            height: 1px;
            border-radius: 16px;
            margin: 20px 0px;
        }

        .label-amount-wrapper {
            margin: 10px 0px;

            p {
                color: $grey-1000;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 0px;
            }

            span {
                color: $grey-1000;
                font-size: 17px;
                font-weight: 700;
            }

            .v-hidden {
                visibility: hidden;
                margin-left: 5px;
            }
        }

        .delivery-method-wrapper {
            color: $orange-700;
            font-size: 16px;
            font-weight: 500;

            h4 {
                margin-bottom: 0px;
            }
        }

        &--btns-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 30px;

            .updated-date-wrapper {
                span {
                    font-weight: 600;
                    font-size: 17px;
                    color: $grey-1000;
                }

                .orange {
                    color: $orange-700 !important;
                    margin-left: 8px;
                }
            }
        }

        .rv-procedure-multi-select {
            background-color: $grey-100;
            border-radius: 16px;

            .ant-select-selector {
                border: none;
                min-height: 48px;
                height: auto;
                background-color: transparent;
            }
        }
    }
}